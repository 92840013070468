.root {
  padding: 12px 16px;
  line-height: 1;
  font-size: 11px;
  margin: 6px auto 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05);

  & :global(svg.lucide) {
    margin-right: 4px;
    width: 11px;
    height: 11px;
  }
}
